@import "../../theme/variables.scss";

.PageLayout {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 50px;

  @include HD {
    padding-top: 30px;
    padding-bottom: 50px;
  }

  &.logout {
    padding: 0;
  }

  &.with-bottom {
    padding-bottom: 0;

    @include HD {
      padding-bottom: 0;
    }
  }

  &.logged-in {
    @include HD {
      padding-left: 70px;
    }
  }
  
  .page {
    padding-top: 10px;

    @include HD {
      padding-top: 30px;
    }
  }

  .bottom-section {
    padding: 30px 0 150px;
    margin-top: 30px;
    background-color: #fff;

    @include HD {
      padding-bottom: 50px;
    }

    .bottom-section-inner {
      width: 90%;
      margin: 0 auto;
  
      @include XGA {
        max-width: 1170px;
      }
    }
  }
}