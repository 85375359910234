@import "../../theme/variables.scss";

.Ranking {

  .rank-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .DropDownTabs {
      &:first-child {
        min-width: 190px;
      }
    }
  }

  .user-ranking {
    .user-ranking-row {
      display: flex;
      align-items: center;
      padding: 0 16px;
      height: 45px;
      font-size: 14px;
      color: $primaryColor;
      font-weight: 700;
      border-bottom: 1px solid rgba(0, 24, 84, 0.2);

      div:first-child {
        font-weight: 400;
        width: 30px;

        @include XGA {
          width: 33.333%;
        }
      }

      div:nth-child(2) {
        width: calc(50% - 15px);

        @include XGA {
          width: 33.333%;
          text-align: center;
        }
      }

      div:last-child {
        width: calc(50% - 15px);
        text-align: right;
        @include XGA {
          width: 33.333%;
        }
      }
    }

    .user-ranking-head {
      height: 52px;
      border-radius: 12px;
      background-color: #0EB5E1;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 16px;

      div:first-child {
        text-indent: -9000px;

        @include XGA {
          text-indent: 0;
          font-weight: 700;
        }
      }
    }
  }
}