@import "./app/theme/variables.scss";

.app {
  padding-top: 88px;

  &.menu-open {
    height: 100vh;
    overflow: hidden;
  }
}
