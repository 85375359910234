@import "../../theme/variables.scss";

.News {
  position: relative;
  width: 90%;
  margin: 0 5%;
  max-width: 1170px;

  @include XGA {
    margin: 0 auto;
  }

  .show-more-cta {
    margin-left: auto;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    text-decoration: underline;
    color: $primaryColor;
    cursor: pointer;
    svg {
      margin-left: 7px;
    }

    @include XGA {
      grid-column: 2 / -1;
    }
  }

  .news-list {
    padding: 0;

    @include XGA {
      display: flex;
      flex-wrap: wrap; 
      justify-content: space-between;
    }

    .news-list-big-item {
      @include XGA {
        position: absolute;
      }
    }
    &.fixed {
      .news-list-big-item {
        @include XGA {
          position: fixed;
          top: 100px;
        }
        @include HD {
          top:80px;
        }
      }
    }

    .news-item-date {
      flex-shrink: 0;
      color: $primaryColor;
      font-size: 10px;
      display: flex;
      align-items: center;
      margin-top: -10px;

      svg {
        margin-right: 5px;
      }
    }
  }
}
