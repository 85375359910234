@import "../../theme/variables.scss";

.nav {
	z-index: 990;
	position: fixed;
	display: flex;
	flex-direction: column;
	top:0;
	right: 0;
	width: 100%;
  max-width: 420px;
	padding: 32px 0 0;
	transform: translate(105%, 0);
	transition: transform, .6s $CubicEaseInOut;
	height: 100vh;

  @include XGA {
    max-width: 375px;
  }

  @include HD {
    z-index: 900;
    width: 70px;
    top: 0;
    right: auto;
    left: 0;
    box-shadow: 0px -3px 50px rgba(45, 45, 45, 0.141444);
    transform: translate(-100%,0);
  }

  .nav-inner {
    display: flex;
    flex-direction: column;
    padding: 40px 25px 25px;
    background-color: #F4F5F9;
    min-height: 100%;
    overflow-y: scroll;
    box-shadow: 0px -3px 50px rgba(45, 45, 45, 0.141444);

    @include HD {
      overflow: visible;
      padding: 40px 0 25px;
      box-shadow: none;
    }

    &:after {
      content: "";
      position: absolute;
      top: 32px;
      left: 0;
      height: 65px;
      width: 100%;
      background-color: #F4F5F9;
    }
  }

  &.active {
  	transform: translate(0,0);
  }

  .logo {
    position: absolute;
    z-index: 9;
    top: 50px;
    left: 25px;

    @include HD {
      display: none;
    }
  }

  .user-name {
    margin-top: 50px;

    @include HD {
      display: none;
    }

    .name {
      font-size: 20px;
      font-weight: 700;
    }

    .title {
      color: $primaryColor;
    }
  }

  .close {
    z-index: 10;
  	position: absolute;
  	top: 48px;
  	right: 5vw;
  	cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include HD {
      right: 25px;
      display: none;
    }
  }

  .profile-section {
  	padding-bottom: 10px;
  	border-bottom: 1px solid #d9deeb;
  }

  .nav-links {
  	padding: 20px 0 20px;

    @include HD {
      padding: 50px 0 20px;
    }
	  
	  .nav-item {
      display: flex;
      align-items: center;
      position: relative;
		  margin: 20px 0 5px;
      padding: 5px 0;
			color: $primaryColor;
			font-size: 20px;
			font-weight: 700;
			text-align: left;
			text-decoration: none;
      cursor: pointer;

      @include HD {
        flex-direction: column;
        font-size: 10px;

        &:hover {
          opacity: .8;
        }
      }

      svg {
        margin-right: 20px;

        @include HD {
          margin: 0;
        }
      }
	   }
  }

  .nav-footer {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 15px;
    font-size: 20px;
		font-weight: 700;
    color: $primaryColor;

    .nav-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      @include HD {
        width: 100%;
        flex-direction: column;
        font-size: 10px;

        &:hover {
          opacity: .8;
        }
      }

      svg {
        margin-right: 20px;

        @include HD {
          margin: 0;
        }
      }
    }
  }
}