@import "../../theme/variables.scss";

.Logout {
    //background:url('./img/bg-mobile.png');
    background-position: center;
    background-size: cover;
    min-height: 812px;
    max-width: 1366px;
    margin: 0 auto;

    @include vHD {
        //background:url('./img/bg.png');
        background-position: center;
        background-size: cover;
        min-height: 660px;
    }


    .content {
        width: 80%;
        margin: 0 auto;
        padding-top: 150px;

        @include XGA {
            padding-top: 120px;
        }

    }

    .icon {
     
        margin-bottom: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            width: 106px;
            height: 106px;

            path {
                fill: #C3C9D8;
            }
            
        }
    }
  

    .title {
        //color: #fff;
        text-align: center;
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        margin: 0 0 .5em;
        
        
       
        span {
            display: block;
        }

        @include XGA {
            font-size: 64px;
            line-height: 64px;
            span {
                display: inline-block;
            }
        }
    }
    .desc {
        text-align: center;
        //color: #fff;
        font-size: 19px;
        @include XGA {
            font-size: 20px;
        }
    }
    .Button {
        margin-top: 120px;

        @include XGA {
            margin-top: 60px;
        }
   
       
    }
   
}