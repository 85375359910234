@import "./variables.scss";

.input-item {
  margin-bottom: 20px;
  text-align: left;

  label {
    display: block;
    font-weight: 700;
    margin-bottom: .5em;

    span {
      font-weight: 400;
      font-size: .8em;
    }
  }

  p {
    margin: 0 0 .7em;
    font-size: .9em;

    &.error {
      margin: 0;
      padding-left: 15px;
      font-size: 10px;
      font-weight: 600;
      color: #F80000;
      //transform: translate(0,-5px);
    }
  }

  &.edit-icon {
    input {
      background-image: url(./img/icon-edit-primary.svg);
      background-repeat: no-repeat;
      background-position: 97% 10px;
    }
  }
}

.input-group {
  display: flex;
  justify-content: space-between;

  .input-item {
    width: 47%;
  }
}

input, select {
  border-radius: 4px;
  border: solid 1px #000;
  padding: 11px 15px;
  outline: none;
  font-size: 16px;
  font-family: "Titillium Web", sans-serif;
  margin-bottom: 5px;
  display: block;
  width: 100%;
  background-color: #f4f5f9;
  -webkit-appearance: none;

  &::placeholder {
    color: #afafaf;
    font-style: italic;
  }
}

select {
  background-image: url(./img/arrow-down.svg);
    background-repeat: no-repeat;
    background-position: 97% 19px;
}

.checkbox-item {
  margin: 0px 0 20px;

  &.indent {
    width: calc(100% - 40px);
    margin-left: 40px;
  }

  label {
    position: relative;
    padding-left: 25px;
    display: block;
    margin-bottom: 5px;
    line-height: 20px;
    font-size: .9em;
    text-align: left;
    cursor: pointer;
    user-select: none;

    @include XGA {
      padding-left: 30px;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      //top: 5px;
      width: 18px;
      height: 18px;
      border: solid 1px $primaryColor;
      border-radius: 3px;
    }

    &:after {
      display: none;
      content: "";
      position: absolute;
      left: 5px;
      top: 5px;
      width: 10px;
      height: 10px;
      background-color: $primaryColor;
      border-radius: 2px;
    }

    a {
      font-size: 1em;
    }

    span {
      font-weight: 400;
      font-size: .8em;
    }
  }

  input[type="checkbox"]:checked + label:after {
    display: block;
  }
  
  input {
    display: none;
  }

  .error {
    margin: 0;
    padding-left: 40px;
    font-size: 10px;
    font-weight: 600;
    color: #F80000;
    //transform: translate(0,-5px);
  }
}

.error-input {
  border: 1px solid #F80000;
}

.form-step {
  display: none;

  &.active {
    display: block;
  }
}