@import "./variables.scss";

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
}

body {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #000;
  line-height: 1.4;
  background: #f4f5f9;
}

.title {
  h1 {
    margin-bottom: 0;
  }

  p {
    margin-top: .5em;
    margin-bottom: 2em;
    color: $primaryColor;
  }
}

h1 {
  font-size: 22px;
  margin-top: 0;
  color: $primaryColor;

  @include XGA {
    font-size: 30px;
  }
}

a {
  text-decoration: underline;
  color: #001854;
  font-weight: 700;

  &:hover {
    text-decoration: none;
  }
}

.page {
  width: 90%;
  margin: 0 auto;
  max-width: 1170px;
}

.dropshadow-box {
  padding: 18px 5%;
  background: linear-gradient(180deg, #ffffff 0%, #fafcff 100%);
  box-shadow: -10px 15px 22px 6px rgba(0, 0, 0, 0.07);
  border-radius: 12px;

  @include XGA {
    padding: 36px;
  }
}

.text-center {
  text-align: center;
}

.text-smaller {
  font-size: .9em;
}

.text-11 {
  font-size: 11px;
}

.margin-20 {
  margin: 20px 0;
}

.color-primary {
  color: $primaryColor;
}

.color-secondary {
  color: $secondaryColor;
}

.color-red {
  color: #FF4444;
}

.color-blue {
  color: $tertiaryColor;
}

.hide-desktop {
  @include XGA {
    display: none;
  }
}

.pagination {
  ul {
    display: flex;
    justify-content: center;
    margin: 2em 0;
    padding: 0;

    li {
      padding: 0;
      margin: 0 3px;
      list-style: none;
      text-align: center;
      border: 1px solid rgb(231, 231, 231);
      border-radius: 4px;
      line-height: 23px;
      cursor: pointer;

      &.disabled {
        a {
          color: rgb(231, 231, 231);
        }
      }

      &.selected {
        border: 1px solid $primaryColor;
      }

      a {
        display: block;
        width: 24px;
        height: 24px;
        text-decoration: none;
        line-height: 24px;
      }
    }
  }
}

.form-columns {
  @include XGA {
    display: flex;
    justify-content: space-between;
  }

  .image-column {
    display: none;

    @include XGA {
      display: block;
      width: 50%;
      margin-top: 80px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .form-column {
    @include XGA {
      width: 38%;
    }

    .form-image-mobile {
      width: 100%;
      height: auto;

      @include XGA {
        display: none;
      }
    }
  }
}