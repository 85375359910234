@import "../../theme/variables.scss";

.mbat {
  position: relative;
  width: 100%;
  max-width: 1170px;
  //padding-bottom: 250px;
  margin-top: -80px !important;

  .dash-head {
    justify-content: space-between;

    @include vHD {
      display: flex;
    }

    .address {
      font-size: 10px;
      color: $primaryColor;

      @include XGA {
        font-size: 16px;
      }
    }

    .sales-point {
      margin: 0px 0 20px;
      font-size: 22px;
      font-weight: 700;
      color: $primaryColor;

      @include XGA {
        font-size: 30px;
      }

      span {
        color: #000;
      }
    }
  }

  .chart-head {
    margin-bottom: 20px;

    .chart-options {
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid $primaryColor;

      @include XGA {
        display: flex;
        justify-content: space-between;
      }

      .data-options {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .DropDownTabs {

          min-width: 190px;

          @include XGA {
            min-width: 190px;
          }
        }

      }
      
      .date-options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;
        padding-top: 12px;
        border-top: 1px solid $primaryColor;
        
        @include XGA {
          margin-top: 0;
          padding-top: 0;
          border: 0;
        }

        .DateSelect {
          display: flex;
          align-items: center;
          font-weight: 700;
          margin-left: auto;
          margin-right: auto;
          @include XGA {
            margin-left: 20px;
            margin-left: 0px;
          }

          .prev-date, .next-date {
            height: 28px;
            cursor: pointer;
          }
        }
      }

      .chart-checkbox {
        margin-bottom: 0;
        font-size: 12px;

        label {
          margin-bottom: 0;
        }

        @include XGA {
          margin-left: 24px;
          font-size: 14px;
        }
      }
    }

    .sold-devices {
      margin: 15px auto;
      color: $primaryColor;
      text-align: center;

      @include XGA {
        font-size: 18px;

        span {
          font-size: .8em;
        }
      }
    }
  }

  .chart-bottom {
    margin-top: 25px;

    .chart-bottom-options {

      @include XGA {
        display: flex;
      }

      .chart-checkbox {
        margin-right: 15px;
      }
    }

    h3 {
      font-size: 14px;
      margin-top: .5em;
      margin-bottom: 0;
      color: $primaryColor;

      @include XGA {
        font-size: 18px;
      }
    }

    p {
      margin-top: 0;
      font-size: 12px;
    }
  }

  .chart-employees {
    display: flex;
    flex-wrap: wrap;
    margin-top: 35px;
  }

  .sumup-box {
    display: flex;
    width: 100%;
    height: 42px;
    padding: 10px 16px;
    background-color: #F4F5F9;
    color: $primaryColor;
    font-size: 12px;
    border-radius: 12px;
    margin-bottom: 12px;
    cursor: pointer;

    @include XGA {
      width: 250px;
      font-size: 14px;
      margin-bottom: 20px;
      margin-right: 20px;
    }

    .sold {
      display: flex;
      align-items: center;
      font-size: 12px;

      .chart-line {
        position: relative;
        width: 20px;
        height: 1px;
        margin-right: 8px;
        background-color: #F4F5F9;

        .chart-line-point {
          position: absolute;
          left: 7px;
          top: -3px;
          width: 7px;
          height: 7px;
          background-color: #F4F5F9;
          border: 1px solid #F4F5F9;
          border-radius: 50%;
        }
      }
    }

    .sumup-top {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .name {
        display: flex;
        align-items: center;
        font-weight: 700;

        .colored-circle {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 12px;
          //margin-top: 7px;
        }

        .employee-checkmark {
          position: relative;
          width: 18px;
          height: 18px;
          border: solid 1px $primaryColor;
          border-radius: 3px;
          margin-right: 10px;
      
          &:after {
            display: none;
            content: "";
            position: absolute;
            left: 3px;
            top: 3px;
            width: 10px;
            height: 10px;
            background-color: $primaryColor;
            border-radius: 2px;
          }

          &.active {
            &:after {
              display: block;
            }
          }
        }
      }
    }

    .sumup-bar {
      width: 100%;
      height: 12px;
      background-color: rgba(255,255,255, .7);
      border-radius: 12px;

      .sumup-bar-percentage {
        margin-top: 6px;
        height: 100%;
        border-radius: 12px;
        background-color: #fff;
      }
    }
  }

  .chart-notice {
    text-align: center;
    font-size: .9em;
    margin-top: 30px;
    margin-bottom: 0;
  }

  .owner-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    //min-width: 70%;
    
    @include vHD {
      align-items: center;
      flex-direction: row;
      justify-content: center;
    }

    .info-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    p {
      margin-left: 15px;
      color: $primaryColor;
      font-size: 13px;

      @include vHD {
        margin: 0 20px;
        font-size: 14px;
      }
    }

    .bat-logo {
      width: 70px;

      svg {
        width: 100%;
        height: auto;
      }
    }

    .Button {
      width: 200px;
    }
  }

  .error-message {
    text-align: center;

    h2 {
      color: $primaryColor;
    }
  }
}
