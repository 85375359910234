@import "../../theme/variables.scss";

.Footer {
  padding: 28px 0;
  background: #F4F5F9;
  box-shadow: 0px -3px 50px rgba(45, 45, 45, 0.141444);
  font-size: 14px;
  color: $primaryColor;

  @include XGA {
    padding: 36px 0 40px;
  }

  .footer-inner {
    position: relative;
    width: 90%;
    margin: 0 auto;
    max-width: 1170px;

    @include XGA {
      padding-left: 20px;
    }

    @include HD {
      margin: 0 auto;
      padding-left: 70px;
    }

    .footer-logo {
      width: 86px;

      @include XGA {
        position: absolute;
        top: 0px;
        left: 0;
      }

      @include HD {
        left: 35px;
      }
    }

    .go-top {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      position: absolute;
      top: 0;
      right: 0;
      background-color: $primaryColor;
      border-radius: 50%;
      cursor: pointer;
    }

    a {
      text-decoration: none;
      font-weight: 400;
      display: block;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }

    .footer-columns {
      @include XGA {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-top: 0px;
      }
    }

    .footer-column {
      @include XGA {
        width: 30%;
      }
    }

    .links-column {
      margin-top: 30px;

      @include XGA {
        width: 20%;
      }

      @include HD {
        width: 17%;
      }

      a {
        display: block;
        margin: 0 0 20px;
        
        @include XGA {

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .contact-column {
      h3 {
        font-size: 18px;
        color: $primaryColor;
        margin: 2em 0 2em;

        @include XGA {
          display: none;
        }
      }
    }

    .contact-row {
      display: flex;
      margin-bottom: 20px;

      @include XGA {
        &:last-child {
          margin-bottom: 0;
        }
      }

      a, p {
        margin-left: 15px;
        width: calc(100% - 39px);
        margin-top: 0;
        margin-bottom: 0;
      }

      p {
        opacity: 0.7;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .rodo-column {
      margin-top: 30px;
    }

    .copy {
      margin-top: 25px;
      padding-top: 20px;
      margin-bottom: 0;
      font-size: 12px;
      opacity: 0.65;
      border-top: 1px solid #ccd1dd;

      @include XGA {
        padding: 0;
        opacity: 1;
        border: 0;
      }
    }
  }
}