@import "../../theme/variables.scss";

.DropDownTabs {
  cursor: pointer;
  border-radius: 12px;
  min-width: 120px;
  position: relative;
  color: $primaryColor;
  height: 28px;
  font-size: 10px;
  font-weight: 700;
  border: 1px solid $primaryColor;

  @include XGA {
    font-size: 14px;
    height: 34px;
  }

  .name {
    display: block;
    text-align: left;
  }

  .drop-down-active {
    position: relative;
    width: 100%;
    padding: 6px 25px 4px 15px;

    @include XGA {
      padding: 5px 30px 5px 10px;
    }

    .arrow {
      position: absolute;
      right: 10px;
      top: 6px;
      transform: rotate(0deg);
      width: 10px;
      height: 5px;

      @include XGA {
        top: 4px;
        right: 11px;
      }
    }

    .arrow-active {
      top: 13px;
      transform: rotate(180deg);

      @include XGA {
        top: 21px;
      }
    }
  }

  .drop-down-list {
    z-index: 100;
    position: absolute;
    top: 25px;
    left: 0;
    background: #f4f5f9;
    width: 100%;
    border-radius: 12px;
    border: 1px solid $primaryColor;
    display: none;
    padding: 0;
    overflow: hidden;
    margin: 5px 0 0;

    @include XGA {
      top: 30px;
    }

    &--active {
      display: block;
    }
  }

  .tab-name {
    display: block;
    width: 100%;
    padding: 6px 15px;
    margin: 0 auto;
  }
  .drop-down-list__item {
    color: $primaryColor;
  }

  .drop-down-list__item:hover > .tab-name {
    background: $primaryColor;
    color: #fff;
    cursor: pointer;
  }
}
